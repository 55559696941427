import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { FaTelegram, FaTiktok, FaInstagram, FaGithub } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";


export default function LogoCollection() {
  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
      >
        Join PumpSloth Online
      </Typography>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        useFlexGap
        sx={{
          color: 'text.secondary',
        }}
      >
        <IconButton
          color="inherit"
          href="https://github.com/pumpsloth"
          aria-label="GitHub"
          sx={{ alignSelf: 'center' }}
          target="_blank"
        >
          <FaGithub />
        </IconButton>
        <IconButton
          color="inherit"
          href="https://twitter.com/PumpSloth"
          aria-label="X"
          sx={{ alignSelf: 'center' }}
          target="_blank"
        >
          <FaXTwitter />
        </IconButton>
        <IconButton
          color="inherit"
          href="https://www.instagram.com/pumpsloth/"
          aria-label="Instagram"
          sx={{ alignSelf: 'center' }}
          target="_blank"
        >
          <FaInstagram />
        </IconButton>
        <IconButton
          color="inherit"
          href="https://t.me/PumpSloth/"
          aria-label="Telegram"
          sx={{ alignSelf: 'center' }}
          target="_blank"
        >
          <FaTelegram />
        </IconButton>
        <IconButton
          color="inherit"
          href="https://www.tiktok.com/pumpsloth/"
          aria-label="Tiktok"
          sx={{ alignSelf: 'center' }}
          target="_blank"
        >
          <FaTiktok />
        </IconButton>
      </Stack>    
    </Box>
  );
}
