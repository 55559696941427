import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import { FaBitcoin, FaCoins } from "react-icons/fa";
import { PiChartLineDownBold, PiChartLineUpBold } from "react-icons/pi";
import { Link } from '@mui/material';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Token Name',
    description:
      'PumpSloth',
  },
  {
    icon: <FaBitcoin />,
    title: 'Token Symbol',
    description:
      '$SLOTH',
  },
  {
    icon: <FaCoins />,
    title: 'Supply',
    description:
      '100.000.000',
  },
  {
    icon: <PiChartLineUpBold />,
    title: 'Buy Tax',
    description:
      '6% total. (2.5% Asset Backing, 2.5% Liquidity, Growth/Marketing 0.4%, 0.5% Staking, 0.1% Protocol Fee)',
  },
  {
    icon: <PiChartLineDownBold />,
    title: 'Sell Tax',
    description:
      '6.9% (NICE!) total. (2.5% Asset Backing, 2.5% Liquidity, Growth/Marketing 0.3%, 1.5% Staking, 0.1% Protocol Fee)',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Token backing asset',
    description:
      <Link href="https://fegex.com/token/bsc/0xbededDf2eF49E87037c4fb2cA34d1FF3D3992A11/(chart//secondary:swap)">$FEG</Link>,
  },
];

export default function Tokenomics() {
  return (
    <Box
      id="tokenomics"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Tokenomics & Details
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            This Token will support the FEG ecosystem in all possible ways and will help building tools that we need for this and other SmartDeFi&trade; projects.
            <br/>
            50% of supply will be on BSC and 50% on BASE initially. This can change over time due to the bridge.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
