import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { FaPiggyBank } from "react-icons/fa";
import { FaMoneyBillTrendUp, FaMoneyBills } from "react-icons/fa6";

const items = [
  {
    icon: <FaMoneyBillTrendUp />,
    title: 'Asset Backing',
    description:
      'With every buy and sell transaction, the minimum value continually increases, stored in a dedicated liquidity pool to back your token and enhance security through diversification.',
    details: 
    <Box m={4}>
      <Typography sx={{ m: 2 }} component="h3" variant="h5" color="text.primary">
        Asset Backing
      </Typography>
      <Typography sx={{ m: 2 }}>Imagine a cryptocurrency that's not just affected by hype and crashes, but has a built-in safety net. That's what SmartDeFi&trade; offers with asset backing.
      </Typography>
      <Typography sx={{ m: 2 }}>
        Here's the gist:
        <ul>
          <li>Every transaction on a SmartDeFi&trade; token contributes to a reserve fund called asset-backing. This fund holds real assets like BNB or USDT or any other coin picked by the project at launch.</li>
          <li>This reserve continuously grows, creating a floor price for the token. Even if the market crashes, the token's base value won't drop below this floor.</li>
          <li>This stability protects you from scams and pump-and-dump schemes. You can finally hold and trade tokens which have real value to them not just momentary hype..</li>
          <li>When people burn their SmartDeFi tokens (take them out of circulation), the remaining tokens become more valuable even faster going forward. This also creates a deflationary effect.</li>
        </ul>
      </Typography>
    </Box>
  },
  {
    icon: <FaPiggyBank />,
    title: 'SmartLending',
    description:
      'Through SmartLending, any SmartDeFi™ token can be used as collateral to take out loans against its baseline value...',
    details: 
      <Box m={4}>
        <Typography sx={{ m: 2 }} component="h3" variant="h5" color="text.primary">
          SmartLending
        </Typography>
        <Typography sx={{ m: 2 }}>Through SmartLending, any SmartDeFi&trade; token can be used as collateral 
          to take out loans against its baseline value - without having to sell or burn the SmartDeFi&trade; Token.
        </Typography>
        <Typography sx={{ m: 2 }}>
          Upon taking the loan, the borrower instantly receives the baseline value for the collateralized Token. 
          The borrower then has 30 days to repay the loan without interest.
        </Typography>
        <Typography sx={{ m: 2 }}>
          If the loan cannot be repaid in time, the user may extend the lending period 
          by another 30 days by burning 0.1% of their collateral, and can extend again and again.
        </Typography>
      </Box>
  },
  {
    icon: <FaMoneyBills />,
    title: 'Staking',
    description:
      'Crypto enthusiasts always seek ways to maximize their holdings and generate passive rewards...',
    details: 
    <Box m={4}>
      <Typography sx={{ m: 2 }} component="h3" variant="h5" color="text.primary">
        Staking
      </Typography>
      <Typography sx={{ m: 2 }}>
        Crypto enthusiasts always seek ways to maximize their holdings and generate 
        passive rewards, and the Staking Protocol offers an opportunity to do just that.
      </Typography>
      <Typography sx={{ m: 2 }}>
        Staking is a system that allows holders to stake their SmartDeFi&trade; tokens 
        and earn rewards from their trading volume on decentralized exchanges such as PancakeSwap or Uniswap.
      </Typography>
      <Typography sx={{ m: 2 }}>
        No deposit or withdrawal fees are associated with staking your tokens, 
        although users are responsible for their gas fees.
      </Typography>
    </Box>
  },
];

export default function Smartdefi() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index: number) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="smartdefi" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              SmartDeFi&trade;
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              We are launching using the launchpad by <Link href="https://feg.io" target="_blank">FEG</Link> which has been audited by two independent companies. 
              (<Link href="https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-FEG-SmartDeFi-v1.1.pdf" target="_blank">peckshield</Link>)
            </Typography>
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : '';
                    }
                    return selectedItemIndex === index ? 'primary.light' : '';
                  },
                  background: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'none' : '';
                    }
                    return selectedItemIndex === index ? 'none' : '';
                  },
                  backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : '',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            {selectedFeature.details}
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor:
                    selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index
                            ? 'primary.main'
                            : 'grey.300';
                        }
                        return selectedItemIndex === index
                          ? 'primary.main'
                          : 'grey.700';
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: 'none' }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                    <Link
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > svg': { transition: '0.2s' },
                        '&:hover > svg': { transform: 'translateX(2px)' },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: '1px', ml: '2px' }}
                      />
                    </Link>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={{
                m: 'auto'
              }}
            >
              {items[selectedItemIndex].details}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
