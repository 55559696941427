import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function WhereToBuy() {
  return (
    <Container
      id="buy"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Where to Buy?
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Currently a LGE is ongoing. <br />
          You can join it at one of the following links.
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">


              <CardActions>
                <Button
                  fullWidth
                  variant='outlined'
                  component="a"
                  href="https://fegex.com/bsc/presale/0xa8B79eCA29dEE5434DDFc4ed8b249F7700D92F68"
                  target="_blank"
                >
                  Join BSC LGE
                </Button>
              </CardActions>
              <CardActions>
                <Button
                  fullWidth
                  variant='contained'
                  component="a"
                  href="https://fegex.com/base/presale/0x50A85Db2Dc078D606Dda7ca7C1DB247E02D70465"
                  target="_blank"
                >
                  Join BASE LGE
                </Button>
              </CardActions>


      </Grid>
    </Container>
  );
}