import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FaCircleHalfStroke, FaCircleCheck, FaRegCircle } from "react-icons/fa6";

const tiers = [
  {
    title: 'Past',
    description: [
      'Create website',
      'Create telegram chat',
      'Setup X account',
      'Create Instagram account',
      'Secure Youtube account',
      'Create other social accounts',
    ],
    icon: <FaCircleCheck/>
  },
  {
    title: 'WIP',
    subheader: 'Work in progress',
    description: [
      'SD Testing',
      'Enhance website',
      'Prepare for LGE',
      'Start social media activities'
    ],
    icon: <FaCircleHalfStroke color='grey.200'/>
  },
  {
    title: 'Future',
    description: [
      'Launch',
      'Create memes',
      '100k volume',
      '1mio volume',
      'Adopt sloths',
      'Get x premium',
      'Consider ETH launch'
    ],
    icon: <FaRegCircle/>
  },
];

export default function Roadmap() {
  return (
    <Container
      id="roadmap"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Roadmap
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Brief overview of our goals we achieved and are about to achieve.
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === 'Future' ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: tier.title === 'WIP' ? '1px solid' : undefined,
                borderColor:
                  tier.title === 'WIP' ? 'primary.main' : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === 'WIP' && (
                    <Chip
                      icon={<FaCircleHalfStroke />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === 'dark' ? 'none' : '',
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                        },
                      }}
                    />
                  )}
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    {tier.icon}
                    <Typography
                      component="text"
                      variant="subtitle2"
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
