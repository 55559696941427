import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { FaTelegram, FaTiktok, FaInstagram, FaGithub } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="/">PumpSloth&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 4, sm: 8 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
        }}
      >
        <div>
          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}
        >
          <IconButton
            color="inherit"
            href="https://github.com/pumpsloth"
            aria-label="GitHub"
            sx={{ alignSelf: 'center' }}
            target="_blank"
          >
            <FaGithub />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://twitter.com/PumpSloth"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
            target="_blank"
          >
            <FaXTwitter />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.instagram.com/pumpsloth/"
            aria-label="Instagram"
            sx={{ alignSelf: 'center' }}
            target="_blank"
          >
            <FaInstagram />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://t.me/PumpSloth/"
            aria-label="Telegram"
            sx={{ alignSelf: 'center' }}
            target="_blank"
          >
            <FaTelegram />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.tiktok.com/pumpsloth/"
            aria-label="Tiktok"
            sx={{ alignSelf: 'center' }}
            target="_blank"
          >
            <FaTiktok />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
